<template>
	<q-card 
		:class="{
			'border-left': selected_field_group.id == field_group.id,
			'q-mb-md q-pa-sm': true
		}"
		:id="`field_group_${field_group.id}`"
	>
		<!-- Form Name -->
		<div class="q-pa-none full-width align-center cursor handle">
			<q-icon
				size="xs"
				color="dark"
				class="rotate-90"
				name="o_drag_indicator"
				:disable="is_inherited"
			>
				<q-tooltip anchor="top middle" :delay="500">
					Drag and drop
				</q-tooltip>
			</q-icon>
		</div>
		<q-item class="full-width row justify-between items-center">
			<div @click.stop class="full-width">
				<div class="row justify-between">
					<div class="row items-center text-left">
						<span class="text-h6" >{{ field_group.title }}</span>
						<q-icon class="q-pl-sm" name="o_edit" />
					</div>
				</div>
				<q-popup-edit v-model="field_group.title" auto-save>
					<template>
						<q-input
							v-model="field_group.title"
							dense
							autofocus
							:readonly="is_inherited"
						/>
					</template>
				</q-popup-edit>
			</div>
		</q-item>
		<!-- ADD FIELD -->
		<q-card-section v-if="this.$store.getters.field_groups[this.fg_id].show_fields" class="row border-bottom q-px-none q-pb-md q-py-none">
			<div class="flex items-baseline text-left grey break col-sm-2 col-md-1 col-12">
				<div class="fixed-height flex items-center">
					Fields
				</div>
			</div>
			<div class="q-pt-none col-sm-10 col-md-11 col-12">
				<!-- LIST FIELDS -->
				<div class="row">
					<q-list
						v-for="(field, index) in field_group.field_mappings"
						:key="field.field.id"
						:style="$q.screen.gt.sm ? 'width:50%;' : 'width:100%;'"
						:class="{
							'display-none': field.deleted,
							'fixed-height items-center flex': true
						}"
					>
						<q-item tag="label" class="items-center full-width">
							<span style="width:30%;" class="text-left">
								{{field.field.label}}
							</span>
							<span style="width:70%;" class="text-right">
								<span>Required</span>
								<q-toggle v-model="field.is_required" :disable="is_inherited"/>
								<q-btn
									flat
									round
									icon="o_close"
									size="sm"
									@click="delete_field(field, index)"
									:disable="is_inherited"
								/>
							</span>
						</q-item>
					</q-list>
				</div>
				<div class="row flex items-center fixed-height" v-if="unused_fields.length">
					<q-item class="q-pa-none" :style="$q.screen.gt.sm ? 'width:50%;' : 'width:100%;'">
						<q-select
							outlined
							v-model="selection"
							:options="unused_fields"
							label="Add field"
							class="full-width"
							:disable="is_inherited"
							@input="selectField"
						/>
					</q-item>
				</div>
			</div>
		</q-card-section>
		<q-card-section v-if="this.$store.getters.field_groups[this.fg_id].show_conditions" class="row border-bottom q-px-none q-py-md">
			<div class="flex items-baseline text-left grey break col-sm-2 col-md-1 col-12">
				<div class="fixed-height flex items-center">
					Conditions
				</div>
			</div>
			<!-- LIST CONDITIONS -->
			<div class="q-pt-none col-sm-10 col-md-11 col-12">
				<div class="row">
					<q-list
						v-for="(condition,index) in field_group.display_conditions" :key="index"
						:style="$q.screen.gt.sm ? 'width:50%;' : 'width:100%;'"
						class="flex items-center fixed-height"
						:class="{'display-none': condition.deleted}"
					>
						<Conditions
							:condition="condition"
							:fg_id="fg_id"
							:cond_index="index"
							:new_conditions="new_conditions"
							:is_inherited="is_inherited"
							:error="error"
							@deleteCondition="deleteCondition"
							@valueChanged="changeConditionValue"
						/>
					</q-list>
				</div>
				<!-- ADD CONDITION -->
				<div class="row flex items-center fixed-height">
					<ConditionAdd
						:style="$q.screen.gt.sm ? 'width:50%;' : 'width:100%;'"
						:field_group="field_group"
						:is_inherited="is_inherited"
						@newFieldGroupSelected="setConditionFG"
						@NewFieldSelected="setConditionField"
						@NewValueSelected="setConditionValue"
						@NewStateSelected="setStateValue"
						@NewCustomerPermissionSelected="customerPermissionSelected"
						@NewMultiValueSelected="setMultiConditionValue">
					</ConditionAdd>
				</div>
			</div>
		</q-card-section>
		<!-- CONTENT -->
		<q-card-section v-if="this.$store.getters.field_groups[this.fg_id].show_content" class="row q-px-none q-py-md">
			<div class="flex items-baseline text-left grey break col-sm-2 col-md-1 col-12">
				<div class="fixed-height flex items-center">
					Content
				</div>
			</div>
			<div class="q-pt-none col-sm-10 col-md-11 col-12 fixed-height flex items-center">
				<q-item class="full-width q-pa-none">
					<q-input
						outlined
						class="full-width"
						v-model="field_group.content"
						:disable="is_inherited"
						label="Add content"
					/>
				</q-item>
			</div>
		</q-card-section>
	</q-card>
</template>

<script>
import Conditions from '@/components/incidents/forms/Conditions.vue';
import ConditionAdd from '@/components/incidents/forms/ConditionAdd.vue';
import FieldsAPI from '@/services/api/IncidentFields.js';
import IncidentMappings from '@/services/api/IncidentFormFieldMapping.js';
import Vue from "vue";
import Utils from '@/services/utils.js';
import { mapActions, mapGetters } from "vuex";
export default {
	name: "FieldGroup",
	components: {
		Conditions,
		ConditionAdd,
	},
	props: {
		fg_id: Number,
		fg_order: String,
		form_id: Number,
		fg_is_open: {
			type: Boolean,
			default: false
		},
		force_save: Boolean,
		is_inherited: Boolean,
		selected_field_group: Object,
		save_fields: Boolean,
	},
	data() {
		return {
			is_open: true,
			show_field_group_settings: false,
			show_delete_dialog: false,
			show_saved: false,
			errors: "",
			unused_fields: [],
			field_group: {},
			new_condition: {added: true},
			error_message:"",
			field_mapping_error: false,
			new_fields: [],
			new_conditions: [],
			selection: '',
			error: {
				has_error: false,
				error_message: '',
				error_field_name: '',
				error_type: '',
				error_value: null
			}
		}
	},
	computed: {
		...mapGetters(['field_groups', 'field_groups_errors', 'form_field_mapping_errors']),
		fg_modified: function () {
			let list_modified = this.field_group.field_mappings.map(function (field_mapping) {
				return field_mapping.modified || field_mapping.added || field_mapping.deleted
			});
			let modified_conditions = this.field_group.display_conditions.map(function (condition) {
				return condition.modified || condition.added || condition.deleted
			});
			list_modified.push(...modified_conditions);
			list_modified.push(this.field_group.content != this.field_groups[this.fg_id].content)
			return list_modified.some(x => !!x)
		},
		content_modified: function() {
			return this.field_group.content != this.field_groups[this.fg_id].content
		},
	},
	methods:{
		...mapActions(['deleteFieldGroup', 'updateFieldGroup', 'updateConditionsOnly']),
		setConditionFG(fg_id) {
			this.$set(this.new_condition, "field_group_id", fg_id);
		},
		setConditionField(field_id) {
			this.$set(this.new_condition, "field_id", field_id);
		},
		setConditionValue(value) {
			this.$set(this.new_condition, "value", value);
			this.$set(this.new_condition, "type", "field");
			let new_condition_copy = Utils.deepCopy(this.new_condition)
			this.new_condition = {added: true};
			this.field_group.display_conditions.push(new_condition_copy)
		},
		setStateValue(value){
			this.$set(this.new_condition, "field_group_id", this.fg_id);
			this.$set(this.new_condition, "type", "state");
			this.$set(this.new_condition, "value", value);

			let new_condition_copy = Utils.deepCopy(this.new_condition)
			this.new_condition = {added: true}
			this.field_group.display_conditions.push(new_condition_copy)
		},
		customerPermissionSelected(value){
			this.$set(this.new_condition, "field_group_id", this.fg_id);
			this.$set(this.new_condition, "type", "permission");
			this.$set(this.new_condition, "value", value);

			let new_condition_copy = Utils.deepCopy(this.new_condition)
			this.new_condition = {added: true};
			this.field_group.display_conditions.push(new_condition_copy)
		},
		setMultiConditionValue(value) {
			this.$set(this.new_condition, "value", value);
			let new_condition_copy = Utils.deepCopy(this.new_condition)
			this.new_condition = {added: true};
			this.field_group.display_conditions.push(new_condition_copy)
		},
		changeConditionValue(new_val, index) {
			this.$set(this.field_group.display_conditions[index], "value", new_val);

			if (this.field_group.display_conditions[index].added) {
				return
			}

			if (this.field_groups[this.fg_id].display_conditions[index]) {
				if (Array.isArray(new_val)) {

					if (!this.arraysEqual(this.field_group.display_conditions[index].value, this.field_groups[this.fg_id].display_conditions[index].value)) {
						this.$set(this.field_group.display_conditions[index], "modified", true);
					}
					else {
						this.$delete(this.field_group.display_conditions[index], "modified");
					}
					return
				}

				if (new_val.value) {
					if (this.field_group.display_conditions[index].value.value != this.field_groups[this.fg_id].display_conditions[index].value.value) {
						this.$set(this.field_group.display_conditions[index], "modified", true);
					}
					else {
						this.$delete(this.field_group.display_conditions[index], "modified");
					}
				}
				else {
					if (this.field_group.display_conditions[index].value != this.field_groups[this.fg_id].display_conditions[index].value) {
						this.$set(this.field_group.display_conditions[index], "modified", true);
					}
					else {
						this.$delete(this.field_group.display_conditions[index], "modified");
					}
				}
			}

		},
		deleteCondition(index) {
			if (this.field_group.display_conditions[index].added) {
				this.field_group.display_conditions.splice(index, 1);
				return
			}
			if(this.field_group.display_conditions[index].deleted){
				this.$delete(this.field_group.display_conditions[index], "deleted")
			}
			else {
				this.$set(this.field_group.display_conditions[index], "deleted", true)
			}
		},
		isRequired(index){
			this.field_group.field_mappings[index].is_required = !this.field_group.field_mappings[index].is_required

			if (!this.field_group.field_mappings[index].added) {
				if (this.field_group.field_mappings[index].is_required !== this.field_groups[this.fg_id].field_mappings[index].is_required) {
					Vue.set(this.field_group.field_mappings[index], "modified", true);
				}
				else {
					Vue.delete(this.field_group.field_mappings[index], "modified");
				}
			}

		},
		async populateFieldDropDown(){
			let all_available_fields = await FieldsAPI.getFields();
			this.unused_fields.splice(0,this.unused_fields.length)

			for (let available_field of all_available_fields) {
				let found_same = false
				for (let field of this.field_group.field_mappings) {
					if (available_field.id === field.field.id) {
						found_same = true
						break
					}
				}

				if (!found_same) {
					this.unused_fields.push(available_field)
				}
			}
		},
		async removeFieldGroup(){
			let conditions_to_be_deleted = []
			this.field_group.field_mappings.forEach(async mapping => {
				conditions_to_be_deleted.push({fg_id: this.field_group.id, field_id: mapping.field.id})
			})

			let response = await this.deleteFieldGroup(this.field_group.id);
			this.closePopup();
			this.$root.$emit("deleteConditionInOtherFG", conditions_to_be_deleted)
			return response
		},
		closePopup() {
			this.show_delete_dialog = false;
		},
		showFieldGroupSettings() {
			if (!this.is_inherited) {
				this.show_field_group_settings = !this.show_field_group_settings
			}
		},
		showDeleteDialog() {
			if (!this.is_inherited) {
				this.show_delete_dialog = !this.show_delete_dialog
			}
		},
		async saveFieldGroup(force_save){
			this.error = {
				has_error: false,
				error_message: '',
				error_field_name: '',
				error_type: '',
				error_value: null
			}
			if (!force_save && this.field_group.id != this.selected_field_group.id){
				return
			}
			if(!this.is_inherited) {
				let fields_clean = this.field_group.field_mappings
					.filter(field => !field.deleted)
					.map(field => ({id: field.id, field: field.field.id, is_required: field.is_required}))
				this.new_fields =  this.field_group.field_mappings
					.filter(field => field.added || field.modified)
					.map(field => (field.id))
	
				let conditions_clean = this.field_group.display_conditions
					.filter(condition => !condition.deleted && !condition.unusable)
					.map(condition => ({
						field_group_id: condition.field_group_id, 
						field_id: condition.field_id,
						value: condition.value,
						type: condition.type,
					}))
				this.new_conditions =  this.field_group.display_conditions
					.filter(condition => condition.added || condition.modified)
					.map(condition => (condition.field_id))
	
				let conditions_to_be_deleted = []
				let any_deleted = false;
				if(fields_clean.length < 1){
					if (this.selected_field_group.id == this.field_group.id){
						this.$emit("selected-field-group-deleted", this.selected_field_group.id)
					}
					let response = await this.removeFieldGroup()
					if (!response.error){
						this.notify(
							'Field group ' + this.field_group.title + ' was deleted because there ware no selected fields.'
						)
					}else{
						this.notify(response.error_message)
					}
					return
				}else {
					let fg_check_errors = Utils.deepCopy(this.field_group)
					let fields_to_be_deleted = []
					fg_check_errors.field_mappings.forEach(async mapping => {
						if (mapping.deleted) {
							fields_to_be_deleted.push(mapping)
							conditions_to_be_deleted.push({fg_id: this.field_group.id, field_id: mapping.field.id});
							any_deleted = true;
						}
					});
					fg_check_errors.field_mappings = fields_clean;
					fg_check_errors.display_conditions = conditions_clean
					let first_response = await this.updateFieldGroup(fg_check_errors)
					if (first_response.error){
						if (first_response.error_field){
							this.error = {
								'has_error': true,
								'error_message': first_response.error_message,
								'error_field_name': first_response.error_field,
								'error_type': first_response.error_field_type,
								'error_value': first_response.error_field_value
							}
							this.notify('The form could not be updated. Please fix the errors.')
							return
						}
						this.notify(first_response.error_message)
						return
					}
					if (any_deleted){
						for (let field of fields_to_be_deleted){
							await IncidentMappings.deleteMapping(fg_check_errors.id, field.id);
						}
						fg_check_errors.field_mappings = fields_clean;
						fg_check_errors.display_conditions = conditions_clean
						let second_response = await this.updateFieldGroup(fg_check_errors)
						if (second_response.error){
							if (second_response.error_field){
								this.error = {
									'has_error': true,
									'error_message': second_response.error_message,
									'error_field_name': second_response.error_field,
									'error_type': second_response.error_field_type,
									'error_value': second_response.error_field_value
								}
								this.notify('The form could not be updated. Please fix the errors.')
								return
							}
							this.notify(second_response.error_message)
							return
						}
					}
					this.field_group = Utils.deepCopy(this.field_groups[this.fg_id])
				}
				this.populateFieldDropDown()
				if(any_deleted) {
					this.$root.$emit("deleteConditionInOtherFG", conditions_to_be_deleted)
				}
				if (!force_save){
					this.notify('Field group saved successfully.')
				}
			}
		},
		delete_field(field, index) {
			if(!this.is_inherited) {
				if (field.added) {
					this.field_group.field_mappings.splice(index, 1);
					this.unused_fields.push(field.field)
					return
				}
				if (field.deleted) {
					Vue.delete(field, "deleted");
				}
				else {
					Vue.set(field, "deleted", true);
				}
			}
		},
		arraysEqual(arr1, arr2) {
			if(arr1.length !== arr2.length)
					return false;
			for(var i = arr1.length; i--;) {
					if(arr1[i].value !== arr2[i].value)
							return false;
			}

			return true;
		},
		documentClick(e) {
			let new_field_group = e.target.closest("div[id^=field_group]")
			if (new_field_group && (this.selected_field_group.id == new_field_group.id.split('_').pop())){ return }
			if (new_field_group && new_field_group.id){
				this.$emit('field-group-selected', new_field_group.id)
			}
		},
		selectField(){
			let index = this.unused_fields.findIndex(x => x.id === this.selection.id);
			this.unused_fields.splice(index, 1);
			this.field_group.field_mappings.push({
				id: this.selection.id,
				is_required: false,
				field: this.selection,
				added: true
			})
			this.selection = ''
		},
		notify(message){
			this.$q.notify({
				timeout: 6700,
				message: message,
				actions: [
					{
						label: 'Dismiss',
						color: 'primary',
					},
				],
			})
		}
	},
	watch: {
		force_save: function() {
			this.saveFieldGroup(true);
		},
		save_fields: function(){
			this.saveFieldGroup(false)
		},
		fg_order: function() {
			this.$set(this.field_group, "order", this.fg_order);
		},
	},
	async created() {
		document.addEventListener("click", this.documentClick);
		this.field_group = Utils.deepCopy(this.field_groups[this.fg_id])
		this.populateFieldDropDown();

		this.$root.$on("deleteConditionInOtherFG", async (conditions_to_be_deleted) => {
			let found = false;

			for (let cond_del of conditions_to_be_deleted) {
				let conditions = this.field_group.display_conditions;
				for (let i = conditions.length - 1; i >=0 ; i--) {
					if (conditions[i].field_group_id == cond_del.fg_id && conditions[i].field_id == cond_del.field_id) {
						this.field_group.display_conditions.splice(i, 1);
						found = true;
					}
				}
			}
			if (found) {
				const payload = {
					fg_id: this.field_group.id,
					conditions: this.field_group.display_conditions
				}

				await this.updateConditionsOnly(payload);
			}
		});
	},
	destroyed(){
		document.removeEventListener("click", this.documentClick);
	},
}
</script>
<style lang="scss" scoped>
	.cursor{
		cursor: all-scroll;
	}
	.cursor:hover{
		background: $grey-2;
	}
	.border-left{
		border-left: 5px solid var(--q-color-primary);
	}
	.display-none{
		display: none;
	}
	.break{
		word-break: break-all;
	}
	.grey{
		color: $grey-8;
		font-size: 14px;
	}
	.border-bottom{
		border-bottom: 1px solid $grey-4;
	}
	.fixed-height{
		min-height: 76px;
		@media(max-width: 599px) {
			min-height: auto;
		}
	}
</style>
<style lang="scss">
	.date-btn .q-btn__wrapper, .close-btn .q-btn__wrapper{
		padding: 0.5rem;
		font-size: 12px;
	}
</style>