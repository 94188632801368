import axios from 'axios';
import store from '@/store/store.js'

export default {
	async deleteMapping(field_group_id, mapping_id) {
		try{
			const response = await axios.delete(`/api/incidents/field_group/${field_group_id}/mapping/${mapping_id}/`)
			store.dispatch('set_form_field_mapping_errors', "");
			return response
		}catch(err){
			const response = {
				error: true,
				error_status: err.response.status,
				error_message: err.response.data,
			}
			if(response.error_status==500){
				store.dispatch('set_form_field_mapping_errors', "An error occured. Please contact your administrator.");
			}else{
				store.dispatch('set_form_field_mapping_errors', response.error_message)
			}
			return response;
		}
	}
}