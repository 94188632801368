<template>
	<q-item
		class="full-width flex items-center q-py-none"
		:tag="field && ['ATTACHMENT', 'CHECKBOX'].includes(field.field_type) ? 'label' : ''"
	>
		<div class="text-left full-width row " v-if="field && field.field_type == 'CHECKBOX'">
			<div style="width:50%;" class="flex items-center">
				{{fg.title}} / {{field.label}}
			</div>
			<div style="width:50%;" class="text-right">
				<q-toggle v-model="condition.value" :disable="is_inherited"/>
			</div>
		</div>
		<q-input
			v-if="field && field.field_type == 'NUMERIC'"
			class="full-width"
			type="number"
			:label="fg.title + ' / ' + field.label"
			v-model="condition.value"
			:disable="is_inherited"
			:error="error_messages['has_error_' + field.label]"
			:error-message="error_messages['error_message_' + field.label]"
			onkeydown="return event.keyCode !== 69 && event.keyCode !== 189 && event.keyCode !== 190"
		/>
		<!-- DATETIME -->
		<q-input v-if="field && field.field_type == 'DATETIME'"
			class="full-width"
			v-model="condition.value"
			:label="fg.title + ' / ' + field.label"
			:disable="is_inherited"
			:error="error_messages['has_error_' + field.label]"
			:error-message="error_messages['error_message_' + field.label]"
			hint="Format DD/MM/YYYY HH:MM:SS"
			@click="date_time_dialog=true"
		>
			<template v-slot:prepend>
				<q-btn flat icon="calendar_today" class="cursor-pointer date-btn" @click="date_time_dialog=true"/>
			</template>
		</q-input>
		<q-dialog v-model="date_time_dialog">
			<div
				class="q-pa-md date-time-container"
				:class="$q.screen.lt.sm ? 'q-px-none q-py-md' : 'q-pa-md'"
				:style="$q.screen.lt.sm ? 'max-width: 88%;' : 'max-width: 1000px;'"
			>
				<div class="q-gutter-md row items-start date-time">
					<q-date
						v-model="condition.value"
						mask="DD/MM/YYYY HH:mm:ss"
						color="primary"
					/>
					<q-time
						format24h
						v-model="condition.value"
						mask="DD/MM/YYYY HH:mm:ss"
						color="primary"
					/>
				</div>
				<div class="q-mt-md flex justify-end full-width">
					<q-btn color="primary" flat label="Close" @click="date_time_dialog = false" />
				</div>
			</div>
		</q-dialog>
		<!-- DATE-->
		<q-input v-if="field && field.field_type == 'DATE'"
			v-model="condition.value"
			class="full-width"
			:label="fg.title + ' / ' + field.label"
			:disable="is_inherited"
			:error="error_messages['has_error_' + field.label]"
			:error-message="error_messages['error_message_' + field.label]"
			hint="Format DD/MM/YYYY"
			@click="show_date_modal = true"
		>
			<template v-slot:prepend>
				<q-btn flat icon="event" class="cursor-pointer date-btn" @click="show_date_modal=true"/>
			</template>
		</q-input>
		<q-dialog v-model="show_date_modal">
			<q-date
				today-btn
				v-model="condition.value"
				mask="DD/MM/YYYY"
				color="primary"
			>
				<div class="row items-center justify-end">
					<q-btn v-close-popup label="Close" color="primary" flat />
				</div>
			</q-date>
		</q-dialog>
		<!-- TIME -->
		<q-input v-if="field && field.field_type == 'TIME'"
			v-model="condition.value"
			class="full-width"
			:label="fg.title + ' / ' + field.label"
			:disable="is_inherited"
			:error="error_messages['has_error_' + field.label]"
			:error-message="error_messages['error_message_' + field.label]"
			hint="Format HH:MM:SS"
			@click="show_time_modal=true"
		>
			<template v-slot:prepend>
				<q-btn flat icon="access_time" class="cursor-pointer date-btn" @click="show_time_modal=true"/>
			</template>
		</q-input>
		<q-dialog v-model="show_time_modal">
			<q-time
				format24h
				v-model="condition.value"
				mask="HH:mm:ss"
				color="primary"
			>
				<div class="row items-center justify-end">
					<q-btn v-close-popup label="Close" color="primary" flat />
				</div>
			</q-time>
		</q-dialog>
		<div class="text-left full-width row items-center" style="height: 100%;" v-if="field && field.field_type == 'ATTACHMENT'">
			<div style="width:70%;" class="flex items-center">
				{{fg.title}} / {{field.label}} has attachment?
			</div>
			<div style="width:30%;" class="text-right">
				<q-toggle v-model="condition.value" :disable="is_inherited"/>
			</div>
		</div>
		<q-input
			v-if=" field && (field.field_type == 'TEXT' || field.field_type == 'EMAIL')"
			class="full-width"
			type="text"
			:label="fg.title + ' / ' + field.label"
			v-model="condition.value"
			:error="error_messages['has_error_' + field.label]"
			:error-message="error_messages['error_message_' + field.label]"
			:disable="is_inherited"
		/>
		<q-select
			v-if="field && field.field_type == 'SINGLESELECT'"
			class="full-width"
			v-model="value_selection"
			:options="value_options"
			:label="fg.title + ' / ' + field.label"
			:option-label="(item) => item.value"
			:error="error_messages['has_error_' + field.label]"
			:error-message="error_messages['error_message_' + field.label]"
			:disable="is_inherited"
		/>
		<q-select
			v-if="field && field.field_type == 'MULTISELECT'"
			class="full-width"
			multiple
			v-model="value_selection"
			:options="value_options"
			:label="fg.title + ' / ' + field.label"
			:error="error_messages['has_error_' + field.label]"
			:error-message="error_messages['error_message_' + field.label]"
			:option-label="(item) => item.value"
			:disable="is_inherited"
		/>
		<q-select
			v-if="!field && condition.type == 'state'"
			class="full-width"
			v-model="value_selection"
			:options="state_values"
			:label="condition.type"
			:error="!value_selection.length"
			error-message="Please select an option"
			:disable="is_inherited"
		/>
		<q-select
			v-if="!field && condition.type == 'permission'"
			class="full-width"
			v-model="value_selection"
			:options="permission_values"
			:label="condition.type"
			:error="!value_selection.length"
			error-message="Please select an option"
			:disable="is_inherited"
		/>
		<q-btn
			flat
			round
			icon="o_close"
			size="sm"
			@click="delete_condition(cond_index)"
			class="items-baseline"
			:disable="is_inherited"
		/>
	</q-item>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
	name: "FieldGroupConditions",
	props: ['fg_id', 'cond_index', 'reset', 'condition', 'new_conditions', 'is_saved', 'is_inherited', 'error'],
	data() {
		return {
			state_values: ['New', 'Active', 'Finished'],
			permission_values:['Admin', 'Operator', 'Sender', 'Data', 'Contact'],
			date_time_dialog: false,
			show_date_modal: false,
			show_time_modal: false,
			field_set: false,
			error_messages: {},
		}
	},

	computed: {
		...mapGetters(['field_groups', 'all_fields']),
		fg_options: {
			get: function() {
				let options = []
				for (let i in this.field_groups) {
					if (i != this.fg_id) {
						options.push({
							id: i,
							label: this.field_groups[i].title
						})
					}
				}
				return options
			},
			set: function(new_val) {}
		},
		field_options: {
			get: function() {
				return this.field_groups[this.condition.field_group_id].field_mappings.map(field_mapping => field_mapping.field)
			},
			set: function(new_val) {}
		},
		value_options: {
			get: function() {
				return this.field.options
			},
			set: function(new_val) {}
		},
		value_selection: {
			get: function() {
				return this.condition.value;
			},
			set: function(new_val, old_val) {
				if (new_val) {
					this.$emit("valueChanged", new_val, this.cond_index)
				}
			}
		},
		fg: function() {
			let id = this.condition.field_group_id
			if (this.field_groups[id]) {
				return this.field_groups[id]
			}
			else {
				return {title: "deleted Field Group"}
			}
		},
		field: function() {
			return this.all_fields[this.condition.field_id]
		},
	},

	methods:{
		...mapActions(['getAllFields']),
		changeIcon(condition){
			if(condition.is_cancel){
				return 'fa fa-undo'
			}
			return 'far fa-times'
		},
		deletedCondition(condition){
			if(condition.is_cancel){
				return true;
			}
			return false;
		},
		changeFields(){
			this.condition.field = "select field";
			this.condition.value = "";
			this.fields = this.field_groups.filter(field_gr => field_gr.id == this.condition.field_group.id)[0].field_mappings.map(field => field.field);
		},
		delete_condition(index) {
			if (!this.is_inherited) {
				this.$emit("deleteCondition", index)
			}
		},
	},
	watch: {
		'condition.value': function(new_val, old_val) {
			if (old_val != null) {
				this.$emit("valueChanged", new_val, this.cond_index)
			}
		},
		error:{
			deep: true,
			handler () {
				if ((this.error.has_error && this.field) || this.condition.value.length == 0){
					if (this.condition.value == this.error.error_value || 
					(typeof(this.condition.value) == 'object' && JSON.stringify(this.condition.value) === JSON.stringify(this.error.error_value))){
						this.error_messages['has_error_' + this.error.error_field_name] = true;
						this.error_messages['error_message_' + this.error.error_field_name] = this.error.error_message
						this.$forceUpdate();
					}
				}
				if (this.field && this.error_messages['has_error_' + this.field.label] && !this.error.has_error){
					this.error_messages['has_error_' + this.field.label] = false;
					this.error_messages['error_message_' + this.field.label] = ''
					this.$forceUpdate();
				}
			}
		},
	}
}
</script>
<style lang="scss" scoped>
	.date-time-container{
		background-color: white;
		display: flex;
		flex-direction: column;
		align-items: center;
	}
	.date-time{
		display: flex;
		flex-direction: row;
		justify-content: center;
	}
</style>