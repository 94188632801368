<template>
	<q-card style="width: 700px; max-width: 80vw">
		<q-card-section class="text-left">
			<div class="text-h5 q-pb-none">Add field group</div>
		</q-card-section>
		<q-card-section class="q-pt-none">
			<q-input
				label="Field group name"
				class="full-width"
				v-model="name_value"
				:error="has_name_error"
				:error-message="name_error_message"
			/>
			<q-select
				v-model="field_selection"
				:options="all_fields_options"
				class="full-width"
				label="Select field"
				:option-label="(item) => item.label"
				:error="has_field_error"
				:error-message="field_error_message"
			/>
		</q-card-section>
		<q-card-actions align='right'>
			<q-btn flat label="Cancel" color="primary" v-close-popup />
			<q-btn flat label="Add" color="primary" @click="addField()"/>
		</q-card-actions>
	</q-card>
</template>

<script>
import { mapGetters } from "vuex";

export default {
	name: "FieldGroupAdd",
	data() {
		return {
			name_value: "",
			field_selection: '',
			add_field_group: false,
			has_name_error: false,
			has_field_error: false,
			name_error_message: '',
			field_error_message: '',

		}
	},
	computed: {
		...mapGetters(['all_fields']),
		all_fields_options: function() {
			return Object.values(this.all_fields);
		}
	},
	methods: {
		addField(){
			this.has_name_error = this.has_field_error = false
			this.name_error_message = this.field_error_message = ''
			if (!this.name_value){
				this.has_name_error = true
				this.name_error_message = "Please enter field group name."
				return
			}
			if (!this.field_selection){
				this.has_field_error = true
				this.field_error_message = "Please select a field."
				return
			}
			this.$emit('newFieldGroupCreated', this.name_value, this.field_selection.id);
			this.name_value = ''
			this.field_selection = ''
			this.add_field_group = false
		}
	},
}
</script>