import { helpers } from "vuelidate/lib/validators";

/** Apply the validator only if prop exists in the component vm */
export const validateIfProp = (prop, validator) =>
	helpers.withParams(
		{ type: 'validatedIf', prop },
		(value, vm) => helpers.ref(prop, this, vm) ? validator(value) : true
	);

/** Apply the validator only if condition is true */
export const validateIfCond = (cond, validator) =>
	helpers.withParams(
		{ type: 'validatedIf', cond },
		(value) => (cond) ? validator(value) : true
	);

/** Check if an item in list has a unique name or label (case-insensitive).
 * Logic is a bit different if item is new (yet to be created) */
export const uniqueIn = (list, item) =>
	helpers.withParams(
		{ type: 'uniqueIn', list },
		(value, vm) => {

			if (!vm[item]) {
				return false;
			}

			let is_item_new = !vm[list].map(it => it.id).includes(vm[item].id);
			let item_list = is_item_new ? vm[list] : vm[list].filter(it => it.id !== vm[item].id);

			return !item_list.find(it => {
				let name = it.name ? it.name : it.label;
				return name.toLowerCase() === value.toLowerCase();
			});
		}
	);

/** REGEX VALIDATORS */
/** Hex Color Code Validator.
	* Skip validation if empty value (consider moving this logic somewhere else (eg. validateIfCond)) */
export const colorCodeHex = value => !value || !!value.match(/^#([0-9a-f]{3}){1,2}$/i);

/** Subdomain Validator
 * The regex is the same used for the SFTP filepath names */
export const subdomain = value => !value || !!value.match(/^[\w\d\-\s_]+$/i);