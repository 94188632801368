<template>
	<q-item
		:class="{
			'flex items-center' : ['value_attachment', 'value_checkbox'].includes(current_display),
			'flex items-baseline q-pa-none' : !['value_attachment', 'value_checkbox'].includes(current_display),
			'q-pt-none': current_display == 'condition_type',
			'q-pt-sm': current_display != 'condition_type',
		}"
		:tag="['value_attachment', 'value_checkbox'].includes(current_display) ? 'label' : ''"
	>
		<q-select
			v-if="current_display == 'condition_type'"
			outlined
			class="full-width"
			v-model="option_selection"
			:options="condition_options"
			label="Add condition"
			:disable="is_inherited"
			@input="selectCondition()"
		/>
		<q-select
			v-if="current_display === 'field_group'"
			outlined
			v-model="fg_selection"
			:options="fg_options"
			:label="title_text"
			class="full-width"
			:disable="is_inherited"
			:option-label="(item) => item.title"
			autofocus
			@input="selectFieldGroup"
			ref="field_group"
			@popup-hide="openItems()"
			:error="has_fg_options_error"
			:error-message="fg_options_error_message"
		>
			<template v-slot:append>
				<q-btn flat icon="o_close" @click.stop="resetValues()" class="cursor-pointer close-btn"/>
			</template>
		</q-select>
		<q-select
			v-if="current_display === 'field'"
			outlined
			v-model="field_selection"
			:options="field_options"
			:label="title_text"
			class="full-width"
			:disable="is_inherited"
			:option-label="(item) => item.label"
			:option-disable="(item) => item.$isDisabled"
			ref="field"
			@popup-hide="openItems()"
			:error="has_base_error"
			:error-message="base_error_message"
		>
			<template v-slot:append>
				<q-btn flat icon="o_close" @click.stop="resetValues()" class="cursor-pointer close-btn"/>
			</template>
		</q-select>
		<q-select
			v-if="current_display === 'value_singleselect'"
			outlined
			v-model="value_selection"
			:options="value_options"
			class="full-width"
			:label="field_selection.label + ' / ' + title_text"
			:disable="is_inherited"
			:option-label="(item) => item.value"
			ref="value_singleselect"
			@popup-hide="openItems()"
			:error="has_singleselect_error"
			:error-message="singleselect_error_message"
		>
			<template v-slot:append>
				<q-btn flat icon="o_close" @click.stop="resetValues()" class="cursor-pointer close-btn"/>
			</template>
		</q-select>
		<div class="text-left full-width row q-px-md" v-if="current_display == 'value_checkbox'">
			<div style="width:50%;" class="flex items-center">{{field_selection.label}}  / {{title_text}}</div>
			<div style="width:50%;" class="text-right">
				<q-toggle v-model="checkbox_value" :disable="is_inherited"/>
			</div>
		</div>
		<q-input v-if="current_display == 'value_input'"
			outlined
			v-model="input_value"
			class="full-width"
			type="text"
			:label="field_selection.label + ' / ' + title_text"
			:disable="is_inherited"
			:error="has_input_error"
			:error-message="input_error_message"
		>
			<template v-slot:append>
				<q-btn flat icon="o_close" @click.stop="resetValues()" class="cursor-pointer close-btn"/>
			</template>
		</q-input>
		<q-input v-if="current_display == 'value_number'"
			outlined
			v-model="input_value"
			class="full-width"
			type="number"
			:label="field_selection.label + ' / ' + title_text"
			:disable="is_inherited"
			:error="has_input_error"
			:error-message="input_error_message"
			onkeydown="return event.keyCode !== 69 && event.keyCode !== 189 && event.keyCode !== 190"
		>
			<template v-slot:append>
				<q-btn flat icon="o_close" @click.stop="resetValues()" class="cursor-pointer close-btn"/>
			</template>
		</q-input>
		<div class="text-left full-width row items-center" style="height: 100%;" v-if="current_display == 'value_attachment'">
			<div style="width:70%;" class="flex items-center">
				{{ field_selection.label + ' / ' + title_text }}
			</div>
			<div style="width:30%;" class="text-right">
				<q-toggle v-model="input_value_attachment" :disable="is_inherited" toggle-order="tf"/>
			</div>
		</div>
		<q-input v-if="current_display == 'value_datetime'"
			outlined
			class="full-width"
			v-model="input_value"
			hint="Format DD/MM/YYYY HH:MM:SS"
			:label="field_selection.label + ' / ' + title_text"
			:disable='is_inherited'
			:error="has_input_error"
			:error-message="input_error_message"
			@click="date_time_dialog=true"
		>
			<template v-slot:prepend>
				<q-btn flat icon="calendar_today" class="cursor-pointer date-btn" @click="date_time_dialog=true"/>
			</template>
			<template v-slot:append>
				<q-btn flat icon="o_close" @click.stop="resetValues()" class="cursor-pointer close-btn"/>
			</template>
		</q-input>
		<q-input v-if="current_display == 'value_date'"
			outlined
			v-model="input_value"
			class="full-width"
			:label="field_selection.label + ' / ' + title_text"
			:disable="is_inherited"
			:error="has_input_error"
			:error-message="input_error_message"
			hint="Format DD/MM/YYYY"
			@click="show_date_modal=true"
		>
			<template v-slot:prepend>
				<q-btn flat icon="event" class="cursor-pointer date-btn" @click="show_date_modal=true"/>
			</template>
			<template v-slot:append>
				<q-btn flat icon="o_close" @click.stop="resetValues()" class="cursor-pointer close-btn"/>
			</template>
		</q-input>
		<q-input v-if="current_display == 'value_time'"
			outlined
			v-model="input_value"
			class="full-width"
			:label="field_selection.label + ' / ' + title_text"
			:disable="is_inherited"
			:error="has_input_error"
			:error-message="input_error_message"
			hint="Format HH:MM:SS"
			@click="show_time_modal=true"
		>
			<template v-slot:prepend>
				<q-btn flat icon="access_time" class="cursor-pointer date-btn" @click="show_time_modal=true"/>
			</template>
			<template v-slot:append>
				<q-btn flat icon="o_close" @click.stop="resetValues()" class="cursor-pointer close-btn"/>
			</template>
		</q-input>
		<q-select
			v-if="current_display === 'value_multiselect'"
			outlined
			multiple
			v-model="multi_value_selection"
			:options="value_options"
			:label="field_selection.label + ' / ' + title_text"
			class="full-width"
			:disable="is_inherited"
			:option-label="(item) => item.value"
			ref="value_multiselect"
			@popup-hide="openItems()"
			:error="has_multiselect_error"
			:error-message="multiselect_error_message"
		>
			<template v-slot:append>
				<q-btn flat icon="o_close" @click.stop="resetValues()" class="cursor-pointer close-btn"/>
			</template>
		</q-select>
		<q-select
			v-if="current_display == 'condition_state'"
			outlined
			v-model="conditions_state_selection"
			:options="state_values"
			:label="title_text"
			class="full-width"
			:disable="is_inherited"
			ref="condition_state"
			@popup-hide="openItems()"
			:error="has_condition_error"
			:error-message="condition_error_message"
		>
			<template v-slot:append>
				<q-btn flat icon="o_close" @click.stop="resetValues()" class="cursor-pointer close-btn"/>
			</template>
		</q-select>

		<q-select
			v-if="current_display == 'customer_permission'"
			outlined
			v-model="customer_permission_selection"
			:options="permission_values"
			:label="title_text"
			class="full-width"
			:disable="is_inherited"
			ref="customer_permission"
			@popup-hide="openItems()"
			:error="has_customer_permission_error"
			:error-message="customer_permission_error_message"
		>
			<template v-slot:append>
				<q-btn flat icon="o_close" @click.stop="resetValues()" class="cursor-pointer close-btn"/>
			</template>
		</q-select>
		<q-btn
			v-if="['value_checkbox', 'value_attachment'].includes(current_display)"
			round
			flat
			color="dark"
			icon="close"
			size="sm"
			:disable="is_inherited"
			style="height: fit-content;"
			@click="resetValues()"
		/>
		<q-btn
			v-if="[
				'value_checkbox',
				'value_input',
				'value_multiselect',
				'condition_state',
				'value_singleselect',
				'customer_permission',
				'value_number',
				'value_attachment',
				'value_datetime',
				'value_date',
				'value_time',
			].includes(current_display)"
			round
			flat
			color="dark"
			icon="check"
			size="sm"
			:disable="is_inherited"
			style="height: fit-content;"
			class="set_center"
			@click="accept_value()"
		/>
		<q-dialog v-model="date_time_dialog">
			<div
				class="q-pa-md date-time-container"
				:class="$q.screen.lt.sm ? 'q-px-none q-py-md' : 'q-pa-md'"
				:style="$q.screen.lt.sm ? 'max-width: 88%;' : 'max-width: 1000px;'"
			>
				<div class="q-gutter-md row items-start date-time">
					<q-date
						v-model="input_value"
						mask="DD/MM/YYYY HH:mm:ss"
						color="primary"
					/>
					<q-time
						format24h
						v-model="input_value"
						mask="DD/MM/YYYY HH:mm:ss"
						color="primary"
					/>
				</div>
				<div class="q-mt-md flex justify-end full-width">
					<q-btn color="primary" flat label="Close" @click="date_time_dialog = false" />
				</div>
			</div>
		</q-dialog>
		<q-dialog v-model="show_date_modal">
			<q-date
				today-btn
				v-model="input_value"
				mask="DD/MM/YYYY"
				color="primary"
			>
				<div class="row items-center justify-end">
					<q-btn v-close-popup label="Close" color="primary" flat />
				</div>
			</q-date>
		</q-dialog>
		<q-dialog v-model="show_time_modal">
			<q-time
				format24h
				v-model="input_value"
				mask="HH:mm:ss"
				color="primary"
			>
				<div class="row items-center justify-end">
					<q-btn v-close-popup label="Close" color="primary" flat />
				</div>
			</q-time>
		</q-dialog>
	</q-item>
</template>

<script>
import { mapGetters } from "vuex";

export default {
	name: "ConditionAdd",
	props: {
		field_group: Object,
		is_inherited: Boolean
	},
	data() {
		return {
			fg_selection: {},
			field_selection: {},
			value_selection: {},
			multi_value_selection: [],
			checkbox_value: true,
			input_value_attachment: true,
			input_value: "",
			field_options: [],
			value_options: [],
			current_display: "condition_type",
			conditions_state_selection: '',
			customer_permission_selection: '',
			state_values: ['New', 'Active', 'Finished'],
			permission_values:['Admin', 'Operator', 'Sender', 'Data', 'Contact'],
			condition_options: ['State', 'Permission'],
			option_selection: '',
			date_time_dialog: false,
			show_date_modal: false,
			show_time_modal: false,
			has_base_error: false,
			has_input_error: false,
			has_condition_error: false,
			has_fg_options_error: false,
			has_multiselect_error: false,
			has_singleselect_error: false,
			has_customer_permission_error: false,
			base_error_message: '',
			input_error_message: '',
			condition_error_message: '',
			fg_options_error_message: '',
			multiselect_error_message: '',
			singleselect_error_message: '',
			customer_permission_error_message: '',
		}
	},
	computed: {
		...mapGetters(['field_groups', 'all_fields']),
		title_text: function() {
			if (this.current_display == "condition_type"){ return "Select type" }
			if (this.current_display == "field_group"){	return "Select field group" }
			if (this.current_display == "field"){ return "Select field" }
			if (["value_input", "value_number"].includes(this.current_display)){ return "Input value" }
			if (this.current_display == "value_datetime"){return "Select date and time"}
			if (this.current_display == "value_date"){return "Select date"}
			if (this.current_display == "value_time"){return "Select time"}
			if (this.current_display == "value_multiselect"){ return "Select values" }
			if (this.current_display == "condition_state"){ return "Select state" }
			if (this.current_display == "customer_permission"){ return "Select customer permission" }
			if (this.current_display == 'value_attachment'){ return "Form has attachment?"}
			if (this.current_display == "value_singleselect" || this.current_display == "value_checkbox"){
				return "Select value"
			}
		},
		fg_options: function() {
			this.has_fg_options_error = false
			this.fg_options_error_message = ""
			let options = Object.values(this.field_groups)
			.filter(field_group => {
				if (field_group.id != this.field_group.id) return field_group
			});
			if (!options.length){
				this.has_fg_options_error = true
				this.fg_options_error_message = "There are no other field groups available."
			}
			return options
		}
	},
	methods: {
		rulesFieldClicked() {
			this.current_display = "field_group";
		},
		conditionStateClicked(){
			this.current_display = "condition_state";
		},
		conditionPermissionClicked(){
			this.current_display = "customer_permission";
		},
		selectFieldGroup(){
			this.$emit('newFieldGroupSelected', this.fg_selection.id);
			this.current_display = "field";
			this.field_options = this.fg_selection.field_mappings.map(field_mapping => field_mapping.field)

			this.field_options.forEach(option => {
				let option_used = false
				this.field_group.display_conditions.forEach(cond => {
					if (option.id === cond.field_id) {
						option_used = true;
					}
				});
				if (option_used) {
					this.$set(option, "$isDisabled", true);
				}
				else {
					this.$delete(option, "$isDisabled", true);
				}
			});
		},
		accept_value() {
			this.resetErrors()
			if (this.current_display == "value_checkbox") {
				this.$emit("NewValueSelected", this.checkbox_value);
			}if (this.current_display == "value_attachment") {
				this.$emit("NewValueSelected", this.input_value_attachment);
			}else if (["value_input", "value_number", "value_datetime", "value_date", "value_time", "value_attachment"].includes(this.current_display)) {
				if (!this.input_value && this.current_display != 'value_attachment'){
					this.has_input_error = true
					this.input_error_message = 'Please input value'
					return
				}
				this.$emit("NewValueSelected", this.input_value);
			}else if (this.current_display == "value_multiselect") {
				if (!this.multi_value_selection.length){
					this.has_multiselect_error = true
					this.multiselect_error_message = 'Please select value'
					return
				}
				this.$emit("NewMultiValueSelected", this.multi_value_selection);
			}else if (this.current_display == "condition_state") {
				if (!this.conditions_state_selection){
					this.has_condition_error = true
					this.condition_error_message = 'Please select state'
					return
				}
				this.$emit("NewStateSelected", this.conditions_state_selection)
			}else if (this.current_display == "customer_permission") {
				if (!this.customer_permission_selection){
					this.has_customer_permission_error = true
					this.customer_permission_error_message = 'Please select customer permission'
					return
				}
				this.$emit("NewCustomerPermissionSelected", this.customer_permission_selection)
			}else if (this.current_display == "value_singleselect"){
				if (!this.value_selection.value){
					this.has_singleselect_error = true
					this.singleselect_error_message = 'Please select value'
					return
				}
				this.$emit("NewValueSelected", this.value_selection);
			}
			this.resetValues()
		},
		resetErrors(){
			this.has_input_error = this.has_multiselect_error = this.has_customer_permission_error =
				this.has_singleselect_error = this.has_condition_error = false
			this.input_error_message = this.multiselect_error_message = this.customer_permission_error_message =
				this.singleselect_error_message = this.condition_error_message = ''
		},
		resetValues(){
			this.current_display = 'condition_type'
			this.option_selection = ''
			this.fg_selection = {}
			this.field_selection = {}
			this.value_selection = {}
			this.checkbox_value = true
			this.input_value_attachment = true
			this.input_value = ""
			this.multi_value_selection = []
			this.conditions_state_selection = ''
			this.customer_permission_selection = ''
		},
		selectCondition(){
			if (this.option_selection == 'Field'){ this.rulesFieldClicked() }
			else if (this.option_selection == 'State'){ this.conditionStateClicked() }
			else if (this.option_selection == 'Permission'){ this.conditionPermissionClicked() }
		},
		openItems(){
			if (!['value_input', 'value_number', 'value_attachment', 'value_datetime', 
				'value_date', 'value_time', 'condition_type', 'value_checkbox'].includes(this.current_display)
			){
				if (this.current_display == 'customer_permission' && this.customer_permission_selection){ return }
				if (this.current_display == 'condition_state' && this.conditions_state_selection){ return }
				if (this.current_display == 'value_singleselect' && this.value_selection.value){ return }
				this.$refs[this.current_display].showPopup()
			}
		},
		checkFieldGroups(){
			if (Object.keys(this.field_groups)){
				if (Object.keys(this.field_groups).length == 1 && this.condition_options.includes('Field')){
					this.condition_options.splice(0, 1)
				}
				else if(Object.keys(this.field_groups).length > 1 && !this.condition_options.includes('Field')){
					this.condition_options.unshift('Field')
				}
			}
		}
	},
	created(){
		this.checkFieldGroups()
	},
	watch: {
		field_selection: function(new_sel) {
			if (new_sel) {
				this.$emit("NewFieldSelected", new_sel.id);
				if (new_sel.field_type === "SINGLESELECT" || new_sel.field_type === "MULTISELECT") {
					if (new_sel.field_type === "MULTISELECT") { this.current_display = "value_multiselect" }
					else { this.current_display = "value_singleselect" }
					this.value_options = new_sel.options
				}else if (new_sel.field_type === "CHECKBOX"){
					this.current_display = "value_checkbox"
				}else if(new_sel.field_type === 'DATETIME'){
					this.current_display = "value_datetime"
				}else if(new_sel.field_type === 'DATE'){
					this.current_display = "value_date"
				}else if(new_sel.field_type === 'TIME'){
					this.current_display = "value_time"
				}else if(new_sel.field_type === 'NUMERIC'){
					this.current_display = "value_number"
				}else if(['TEXT', 'EMAIL'].includes(new_sel.field_type)){
					this.current_display = "value_input"
				}else if(new_sel.field_type === 'ATTACHMENT'){
					this.current_display = "value_attachment"
				}else{ this.current_display = 'condition_type' }
			}
		},
		conditions_state_selection: function(new_sel, old_sel) {
			if (new_sel == null) {new_sel = old_sel}
		},
		customer_permission_selection: function(new_sel, old_sel) {
			if (new_sel == null) {new_sel = old_sel}
		},
		field_groups: function(){
			this.checkFieldGroups()
		}
	},
}
</script>
<style lang="scss" scoped>
	.date-time-container{
		background-color: white;
		display: flex;
		flex-direction: column;
		align-items: center;
	}
	.date-time{
		display: flex;
		flex-direction: row;
		justify-content: center;
	}
</style>